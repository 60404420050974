import { NextPage } from 'next/types';
import { AppProps } from 'next/app';
import React, { ReactElement, ReactNode } from 'react';
import '../styles/globals.scss';
import 'swiper/scss/navigation';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page: any) => page);
  return getLayout(<Component {...pageProps} />);
}
